.privacy-policy {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; 
    padding: 1em;
    color: white;
}

.title {
    -webkit-text-stroke: 0.2vw black;
    color: white;
    font-size: 3vw;
    text-align: center;
}