/* Attribution.css */

footer {
    height: 8vh;
    width: 100%;
    background-color: #242222;
}

p.copyright {
    width: 100%;
    color: #fff;
    font-size: 2vw;
    text-align: center;
}

@media screen and (max-width: 750px) {
    p.copyright {
        font-size: 8vw;
    }
}
