.title-container {
    text-align: center;
  }
  
  .home-title {
    margin-top: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    color: white;
  }
  
  .home-subtitle {
    margin-top: -10px;
    color: #cccccc;
  }
  
  .text-background {
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    color: white; /* White text color */
    border-radius: 8px; /* Rounded corners */
    padding: 20px;
    margin-bottom: 20px;
    width: 90%; /* Adjust width as needed */
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
}
  
  .home-container {
    padding-top: 20px;
  }
  
  .app-icon {
    width: 100px;
    height: 100px;
    border-radius: 15px; /* Rounded corners */
  }
  
  .link-container {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  
  .app-link {
    color: white !important; 
    text-decoration: none;
  }
  
  .app-link:hover {
    text-decoration: underline;
  }
  
  .link-separator {
    margin: 0 10px;
    color: white;
  }
  
  .app-title {
    font-weight: bold;
  }
  
  .common-title {
    text-align: center;
    color: #ffffff;
}