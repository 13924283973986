* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
}

#root {
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

body {
    background-color: #242222;
    margin: 0; /* Reset default margin */
    padding: 0; /* Reset default padding */
}

.bg-container {
    width: 100vw;
    height: 100vh;
    background-image: url('../images/BG.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.bg-cipher-container {
    width: 100vw;
    height: 100vh;
    background-image: url('../images/BGCipher.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

/* Navbar.css (if you have separate CSS for Navbar) */
.navbar {
    z-index: 1000; /* Ensure Navbar is above background */
    /* Add your Navbar styles here */
}