.container {
    margin-top: 0;
    margin-bottom: 4rem;
    padding: 2rem;
    border-radius: 1rem;
  }
  
  .paper {
    padding: 2rem;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .form-field {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 0.5rem;
    color: #000;
  }
  
  .submit-button {
    color: #fff;
    font-weight: bold;
    background: #000;
  }
  
  .alert {
    padding: 0.75rem;
    margin-top: 1rem;
    border-radius: 0.5rem;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  
  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
  
  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .text-background {
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    color: white; /* White text color */
    border-radius: 8px; /* Rounded corners */
    padding: 20px;
    margin-bottom: 20px;
    width: 90%; /* Adjust width as needed */
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
}
  