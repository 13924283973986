.navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    padding: 0 20px;
  }
  
  .navbar-left {
    display: flex;
    align-items: center;
  }
  
  .navbar-logo {
    margin-right: 20px;
  }
  
  .menu-icon {
    display: none;
  }
  
  .nav-menu {
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .nav-item {
    margin: 0 10px;
  }
  
  .nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
  }
  
  .nav-links:hover {
    background-color: #9f9e9e;
    border-radius: 4px;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars, .fa-times {
    color: #fff;
    font-size: 1.8rem;
  }
  
  @media screen and (max-width: 960px) {
    .menu-icon {
      display: block;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #9f9e9e;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  }
  