table {
    width: 50%;
    background-color: #80e3f7;
    color: #000000;
    border-collapse: collapse;
    margin: 0 auto; /* Center the table */
}

thead tr {
    background-color: #4c4747;
    color: #ffffff;
}

th, td {
    padding: 10px;
    border: 1px solid #000000;
}

.app-icon {
    width: 100px; /* Adjust size as needed */
    height: auto;
}

.app-title {
    font-size: 1.5em; /* Increase font size */
}

.common-title {
    text-align: center;
    color: #ffffff;
}
