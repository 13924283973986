table, th, td {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid black;
    border-collapse: collapse;
    text-align: center;
    padding: 5px;
}

td {
    padding: 5px;
}

.app-icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 10vh;
    height: 10vh;
}

.app-icon-large {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 25vh;
    height: 25vh;
}

.app-screenshot {
    margin: 0 auto 0 6.5vw;
    width: 11.8vw;
    border: 2px solid black;
}

.app-description {
    -webkit-text-stroke: 0.2vw black;
    color: white;
    font-size: 3vw;
    max-width: 80vw;
    margin: 5vh auto auto auto;
    text-align: center;
}